import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import ImageEntries from '../../components/ImageEntries'

import introLogo from '../../assets/brandbook/images/logo-intro.svg'
import introColors from '../../assets/brandbook/images/colors-intro.svg'
import introTypography from '../../assets/brandbook/images/typography-intro.svg'
import introIcons from '../../assets/brandbook/images/icons-intro.svg'
import introImages from '../../assets/brandbook/images/images-intro.jpg'
// import introAnimation from '../../assets/brandbook/images/animation-intro.svg'
import introForTheOffice from '../../assets/brandbook/images/ForTheOffice-intro.jpg'
// import introStationary from '../../assets/brandbook/images/logo_on_cards.jpg'
// import introClothing from '../../assets/brandbook/images/clothing-intro.jpg'
import introGUI from '../../assets/brandbook/images/gui.jpg'

export default function Brandbook({ data: { hero }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="Guidelines, tools and rules" />

      <ImageEntries
        items={[
          {
            url: '/brandbook/logo/',
            image: introLogo,
            title: 'Logotype',
            color: 'dark',
            preamble:
              'Our logo is one of the most recognizable visual elements in our toolset. ',
          },
          {
            url: '/brandbook/colors/',
            image: introColors,
            title: 'Colors',
            color: 'dark',
            preamble:
              'Our primary colours are Yellow, Blue, Dark Gray, Gray and Light Gray. ',
          },
          {
            url: '/brandbook/typography/',
            image: introTypography,
            title: 'Typography',
            color: 'dark',
            preamble: 'We use Wigrum as our typeface. ',
          },
          {
            url: '/brandbook/icons/',
            image: introIcons,
            title: 'Icons',
            color: 'dark',
            preamble:
              'We have our own custom icon and illustration language drawn on the characteristics of our font, Wigrum. ',
          }, /* {
            url: '/brandbook/images/',
            image: introImages,
            title: 'Images',
            color: 'light',
            preamble:
              'Our photography style is simple and contemporary, using real people in real scenes.',
          } */
          {
            url: '/brandbook/for-the-office/',
            image: introForTheOffice,
            title: 'For The Office',
            color: 'light',
            preamble:
              'To stay on brand, everything needs to move in the right direction. ',
          },
          {
            url: '/brandbook/interactive-elements/',
            image: introGUI,
            title: 'Interactive elements',
            color: 'light',
            preamble:
              'UX and UI. ',
          },
        ]}
      />
    </>
  )
}

Brandbook.propTypes = {
  data: PropTypes.object.isRequired,
}

Brandbook.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/ncl_sweater/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
