import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link } from 'gatsby'

const bem = new BEMHelper('image-entries')

export default function ImageEntries({ items }) {
  return (
    <nav {...bem('')}>
      {items.map(({ url, image, title, description, color }, index) => (
        <Link to={url} key={url + index} {...bem('item', color)}>
          <img src={image} {...bem('image')} alt={title} />
          <h2 {...bem('title')}>{title}</h2>
          <p {...bem('preamble')}>{description}</p>
        </Link>
      ))}
    </nav>
  )
}

ImageEntries.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
